$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import 'plugins';
@import 'style';
@import 'responsive';
@import 'xsNavMenu';
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'owl.carousel';
